#video-container {
    width: 400px;
    height: 400px;
    overflow: hidden;
    margin: auto;
}

#video {
    max-width: 400px;
    max-height: 400px;
    transform-origin: center;
    display: inline-block;
    vertical-align: middle;
    height: 100%;
}

#player {
    margin: auto;
    text-align: center;
}

#logo {
    margin-left: auto;
    margin-right: auto;
    width: 760px;
    height: 100px;
    text-align: left;
    background: #ffffff url(image/logo.jpg) no-repeat;
    color: #5a6370;
}

#logo h1 {
    margin: 0px;
    padding: 41px 0px 0px 19px;
    font-size: 200%;
    letter-spacing: 0.2em;
    background: transparent;
    color: #ffffff;
}

#main {
    width: 760px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    box-shadow: 0px 0px 30px 10px black;
    background-color: white;
    margin-top: 2em;
}

body {
    font-family: verdana, arial, sans-serif;
    padding: 0px;
    margin: 0px;
    /* font-size: 1em; */
    background-color: #5a6370;
}

#rotator {
    padding: 0.5em;
}

button {
    margin: 0.1em;
}

#footer {
    text-align: center;
}

#footer {
    background: #ffffff;
    /* color: #d1d3d7; */
    border-color: #d7d7d7;
    padding: 0.5em;
}

#thanks {
    padding: 0.5em;
}

.wevideo-ad {
    background-color: rgb(201, 216, 221);
    padding: 0.5em;
    font-size: 1.25em;
    margin: auto;
}

#link-bar {
    margin: 0.5em;
}
