#video-container {
  width: 400px;
  height: 400px;
  margin: auto;
  overflow: hidden;
}

#video {
  transform-origin: center;
  vertical-align: middle;
  max-width: 400px;
  height: 100%;
  max-height: 400px;
  display: inline-block;
}

#player {
  text-align: center;
  margin: auto;
}

#logo {
  text-align: left;
  color: #5a6370;
  background: #fff url("logo.4b96d141.jpg") no-repeat;
  width: 760px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
}

#logo h1 {
  letter-spacing: .2em;
  color: #fff;
  background: none;
  margin: 0;
  padding: 41px 0 0 19px;
  font-size: 200%;
}

#main {
  background-color: #fff;
  width: 760px;
  margin-top: 2em;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  box-shadow: 0 0 30px 10px #000;
}

body {
  background-color: #5a6370;
  margin: 0;
  padding: 0;
  font-family: verdana, arial, sans-serif;
}

#rotator {
  padding: .5em;
}

button {
  margin: .1em;
}

#footer {
  text-align: center;
  background: #fff;
  border-color: #d7d7d7;
  padding: .5em;
}

#thanks {
  padding: .5em;
}

.wevideo-ad {
  background-color: #c9d8dd;
  margin: auto;
  padding: .5em;
  font-size: 1.25em;
}

#link-bar {
  margin: .5em;
}

/*# sourceMappingURL=index.d905c285.css.map */
